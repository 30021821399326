
body,div,span,header,footer,nav,section,aside,article,ul,dl,dt,dd,li,a,p,h1,h2,h3,h4,h5,h6,i,b,textarea,button,input,select,figure,figcaption{padding:0;margin:0;list-style:none;font-style:normal;text-decoration:none;border:none;font-family:'Avenir', Helvetica, Arial, sans-serif;-webkit-font-smoothing:antialiased;-moz-osx-font-smoothing:grayscale;background:none;-webkit-tap-highlight-color:transparent
}
body:focus,div:focus,span:focus,header:focus,footer:focus,nav:focus,section:focus,aside:focus,article:focus,ul:focus,dl:focus,dt:focus,dd:focus,li:focus,a:focus,p:focus,h1:focus,h2:focus,h3:focus,h4:focus,h5:focus,h6:focus,i:focus,b:focus,textarea:focus,button:focus,input:focus,select:focus,figure:focus,figcaption:focus{outline:none
}
html,body{min-width:980px;color:#4A4A4A
}
img{display:block
}
::-webkit-scrollbar{width:8px;height:8px;border:0;background-color:#fff
}
::-webkit-scrollbar-track{-webkit-box-shadow:inset 0 0 1px rgba(238,234,234,0.89);border-radius:10px;background-color:#F5F5F5
}
::-webkit-scrollbar-thumb{border-radius:10px;-webkit-box-shadow:inset 0 0 6px rgba(238,234,234,0.89);background-color:#eee
}
.report-icon-box{position:fixed;top:0;right:0;-webkit-transform:translate(-30px, 180px);transform:translate(-30px, 180px);display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-orient:vertical;-webkit-box-direction:normal;-ms-flex-direction:column;flex-direction:column;-webkit-box-pack:center;-ms-flex-pack:center;justify-content:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;z-index:99999;font-weight:600;color:#4a4a4a
}

#nav-ul[data-v-8bcd6aa2]{display:-webkit-box;display:-ms-flexbox;display:flex;list-style:none;-webkit-box-align:center;-ms-flex-align:center;align-items:center;width:980px;margin:0 auto
}
#nav-ul .nav-item[data-v-8bcd6aa2]{padding:22px;font-size:14px;line-height:22px;font-weight:500
}
#nav-ul .nav-item[data-v-8bcd6aa2] :not(:first-child){cursor:pointer
}
#nav-ul .nav-item a[data-v-8bcd6aa2]{color:#4A4A4A;cursor:pointer
}
#nav-ul .nav-item:not(:first-child):hover a[data-v-8bcd6aa2]{color:#FF2F86
}
#nav-ul .nav-item[data-v-8bcd6aa2]:first-child{padding:0;margin-right:48px
}
#nav-ul .nav-item:first-child #ug-logo[data-v-8bcd6aa2]{width:48px
}
#nav-ul .nav-item[data-v-8bcd6aa2]:nth-child(-n+5),#nav-ul .nav-item[data-v-8bcd6aa2]:nth-child(-n+6){display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-align:baseline;-ms-flex-align:baseline;align-items:baseline
}
#nav-ul .active[data-v-8bcd6aa2]{border-bottom:solid 2px #F39
}
#nav-ul .active a[data-v-8bcd6aa2]{color:#FF2F86
}

#foot-box[data-v-2355964c]{background-color:#3D3D3D
}
#foot-box main[data-v-2355964c]{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-pack:center;-ms-flex-pack:center;justify-content:center;-webkit-box-align:start;-ms-flex-align:start;align-items:flex-start;padding:100px
}
#foot-box hr[data-v-2355964c]{margin:0 auto;width:980px;border:solid .5px #545454
}
#foot-img[data-v-2355964c]{width:102px;padding-right:40px
}
.footer-info[data-v-2355964c]{padding:0 40px
}
.footer-info h1[data-v-2355964c]{margin-bottom:20px;font-size:16px;color:#ffffff
}
.footer-info li[data-v-2355964c]{font-size:14px;line-height:24px;font-weight:500
}
.footer-info li a[data-v-2355964c]{color:#a9a7a7
}
.footer-info li:hover a[data-v-2355964c]{color:#F39
}
#record-gongan[data-v-2355964c]{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-pack:center;-ms-flex-pack:center;justify-content:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;margin-top:20px;padding-bottom:10px
}
#record-gongan a[data-v-2355964c]{font-size:14px;color:#ffffff
}
#record[data-v-2355964c]{padding-bottom:20px;text-align:center;color:#8E8E8E;font-size:14px
}

.download-box[data-v-1f069850]{display:-webkit-box;display:-ms-flexbox;display:flex;-ms-flex-pack:distribute;justify-content:space-around;position:relative;width:100%
}
.download-box .download-side[data-v-1f069850]{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-orient:horizontal;-webkit-box-direction:reverse;-ms-flex-direction:row-reverse;flex-direction:row-reverse;-webkit-box-pack:center;-ms-flex-pack:center;justify-content:center;-webkit-box-align:start;-ms-flex-align:start;align-items:flex-start;font-weight:bold
}
.download-box .left[data-v-1f069850]{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-orient:vertical;-webkit-box-direction:normal;-ms-flex-direction:column;flex-direction:column;-webkit-box-pack:center;-ms-flex-pack:center;justify-content:center;-webkit-box-align:start;-ms-flex-align:start;align-items:flex-start
}
.download-box .left h1[data-v-1f069850]{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-align:center;-ms-flex-align:center;align-items:center;font-size:20px;margin-bottom:20px
}
.download-box .left p[data-v-1f069850]{font-size:18px
}
.ugirl-logo[data-v-1f069850]{width:80px;margin-right:20px
}
.download-btn[data-v-1f069850]{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-align:center;-ms-flex-align:center;align-items:center;-webkit-box-pack:center;-ms-flex-pack:center;justify-content:center;width:150px;height:50px;color:#ff3399;font-size:20px;font-weight:bold;letter-spacing:1px
}
.download-btn img[data-v-1f069850]{margin-right:3px
}
.download-img[data-v-1f069850]{width:190px;margin-right:20px
}
.downloadImgNew[data-v-1f069850]{display:inline-block;border:1px solid #d9d9d9;text-align:center;-webkit-box-sizing:border-box;box-sizing:border-box;height:190px;padding-top:32px;cursor:pointer
}
.downloadImgNew[data-v-1f069850]:hover{background:#ff4081;color:#fff
}

.title-box[data-v-3732fb8c]{margin-bottom:10px;text-align:center
}
.title-box .first-title[data-v-3732fb8c]{color:#F62F86;font-size:28px
}
.title-box .second-title[data-v-3732fb8c]{font-size:20px
}

section[data-v-6298ef91]{position:relative;height:auto;min-height:612.44px
}
section .bg-img[data-v-6298ef91]{width:100%;height:615px
}
section .subarea[data-v-6298ef91]{-webkit-box-sizing:border-box;box-sizing:border-box;display:-webkit-box;display:-ms-flexbox;display:flex;-ms-flex-pack:distribute;justify-content:space-around;-webkit-box-align:center;-ms-flex-align:center;align-items:center;position:absolute;top:0;left:50%;-webkit-transform:translateX(-50%);transform:translateX(-50%);width:980px;height:100%
}
section .banner-area[data-v-6298ef91]{-webkit-box-orient:vertical;-webkit-box-direction:normal;-ms-flex-direction:column;flex-direction:column;-webkit-box-align:center;-ms-flex-align:center;align-items:center
}
section .download-area[data-v-6298ef91]{-webkit-box-orient:vertical;-webkit-box-direction:normal;-ms-flex-direction:column;flex-direction:column;-webkit-box-pack:center;-ms-flex-pack:center;justify-content:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center
}
section .download-area .title-box[data-v-6298ef91]{margin-bottom:100px
}

#intro-img[data-v-f23fe92a]{position:absolute;left:0;width:701px
}
#intro[data-v-f23fe92a]{position:absolute;width:410px;right:0
}
#intro .title-box[data-v-f23fe92a]{text-align:left
}
#intro p[data-v-f23fe92a]{padding-top:10px;text-indent:32px;font-size:16px
}
#intro #intro-btn[data-v-f23fe92a]{padding:13px 50px;margin-top:20px;font-size:16px;color:#f62f86;border:solid 2px #f39;border-radius:40px;cursor:pointer;font-weight:600;letter-spacing:1px
}
#intro #intro-btn[data-v-f23fe92a]:hover{background-color:#f39;color:#fff
}
#intro #intro-arrow[data-v-f23fe92a]{position:relative;-webkit-animation:arrow-data-v-f23fe92a 500ms infinite ease-in-out alternate;animation:arrow-data-v-f23fe92a 500ms infinite ease-in-out alternate
}
@-webkit-keyframes arrow-data-v-f23fe92a{
0%{left:0;opacity:1
}
0%{left:7px;opacity:0
}
}
@keyframes arrow-data-v-f23fe92a{
0%{left:0;opacity:1
}
0%{left:7px;opacity:0
}
}
.service-block[data-v-f23fe92a]{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-orient:vertical;-webkit-box-direction:normal;-ms-flex-direction:column;flex-direction:column;-webkit-box-align:center;-ms-flex-align:center;align-items:center
}
.service-block img[data-v-f23fe92a]{cursor:pointer
}
.service-block img[data-v-f23fe92a]:hover{-webkit-transform:translateY(-15px);transform:translateY(-15px)
}
.hot-block[data-v-f23fe92a]{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-orient:vertical;-webkit-box-direction:normal;-ms-flex-direction:column;flex-direction:column;-webkit-box-align:center;-ms-flex-align:center;align-items:center
}
.hot-block p[data-v-f23fe92a]{font-size:14px
}
.hot-block .title-box[data-v-f23fe92a]{margin-bottom:20px
}
.hot-left .title-box[data-v-f23fe92a]{padding-top:30px
}
.hot-left .hot-left-img[data-v-f23fe92a]{float:left
}
.hot-left p[data-v-f23fe92a]{width:160px;cursor:pointer;overflow:hidden;white-space:nowrap;text-overflow:ellipsis;line-height:24px
}
.hot-left p[data-v-f23fe92a]:nth-child(-n+2){margin-top:20px
}
.hot-left p[data-v-f23fe92a]:nth-child(-n+3){color:#f39
}
.hot-left p[data-v-f23fe92a]:hover{color:#f39
}
.hot-right[data-v-f23fe92a]{position:relative;left:-10px
}
.hot-right #model-box[data-v-f23fe92a]{display:-webkit-box;display:-ms-flexbox;display:flex;width:320px;-ms-flex-wrap:wrap;flex-wrap:wrap;-webkit-box-pack:center;-ms-flex-pack:center;justify-content:center
}
.model[data-v-f23fe92a]{padding:5px
}
.model img[data-v-f23fe92a]{padding:5px;-webkit-box-shadow:0 0 8px 3px #aaaaaa;box-shadow:0 0 8px 3px #aaaaaa
}
.model p[data-v-f23fe92a]{font-size:12px;margin:10px 0 5px 0
}
.model p a[data-v-f23fe92a]{color:#f39;padding:2px 10px;border-radius:20px;border:solid 1px #f39;margin-left:3px;font-weight:500;cursor:pointer
}
.model p a[data-v-f23fe92a]:hover{background-color:#f39;color:#fff
}

.banner-img[data-v-7a1a115e]{width:980px;cursor:pointer
}
.banner-img[data-v-7a1a115e]:hover{-webkit-filter:brightness(80%);filter:brightness(80%)
}
#thumbnail[data-v-7a1a115e]{display:-webkit-box;display:-ms-flexbox;display:flex;-ms-flex-wrap:wrap;flex-wrap:wrap;-webkit-box-pack:center;-ms-flex-pack:center;justify-content:center;width:980px;padding-top:10px
}
#thumbnail .thumb-item[data-v-7a1a115e]{position:relative;width:200px;margin:0 5px;cursor:pointer
}
#thumbnail img[data-v-7a1a115e]{width:100%
}
#thumbnail h3[data-v-7a1a115e]{position:absolute;bottom:0;width:100%;text-align:center;background-color:rgba(225,225,225,0.7);font-size:16px;line-height:26px;color:#252121
}
#thumbnail .active[data-v-7a1a115e]{border-bottom:solid 4px #F62F86
}

#shop[data-v-77ecf1d2]{width:980px;margin:0 auto;padding:40px 0
}

.hori-title[data-v-48cd309e]{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-pack:start;-ms-flex-pack:start;justify-content:flex-start;-webkit-box-align:end;-ms-flex-align:end;align-items:flex-end;padding:10px 0
}
.hori-title .cn[data-v-48cd309e]{color:#F62F86;font-size:16px
}
.hori-title .en[data-v-48cd309e]{margin-left:5px;font-size:12px
}

#gallery-box[data-v-f32562d4]{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-pack:justify;-ms-flex-pack:justify;justify-content:space-between;-webkit-box-align:start;-ms-flex-align:start;align-items:flex-start;-ms-flex-wrap:wrap;flex-wrap:wrap;width:100%;margin-top:20px
}

.gallery-item[data-v-a1db32ee]{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-pack:center;-ms-flex-pack:center;justify-content:center;-webkit-box-align:start;-ms-flex-align:start;align-items:flex-start;padding-bottom:30px
}
.gallery-item img[data-v-a1db32ee]{width:137px;margin-right:10px;cursor:pointer
}
.gallery-item aside[data-v-a1db32ee]{width:130px
}
.gallery-item h3[data-v-a1db32ee]{height:40px;overflow:hidden;text-overflow:ellipsis;font-size:14px;color:#4A4A4A;cursor:pointer
}
.gallery-item .hr[data-v-a1db32ee]{display:-webkit-box;display:-ms-flexbox;display:flex;margin:5px 0
}
.gallery-item .hr[data-v-a1db32ee] :first-child{width:30px;height:1px;background-color:#F39
}
.gallery-item .hr[data-v-a1db32ee] :last-child{-webkit-box-flex:1;-ms-flex:1;flex:1;height:1px;background-color:#4A4A4A
}
.gallery-item .name[data-v-a1db32ee]{font-size:12px;color:#BCBCBC
}
.gallery-item .time[data-v-a1db32ee]{font-size:12px;font-weight:500
}
.gallery-item .price[data-v-a1db32ee]{margin-top:20px;font-size:18px;font-weight:bold;color:#F39
}
.gallery-item .advisory[data-v-a1db32ee]{display:inline-block;width:112px;height:30px;margin-top:20px;border:solid 1px #cecece;border-radius:20px;text-align:center;line-height:30px;color:#FF2F86;font-size:12px;font-weight:bold;cursor:pointer
}
.gallery-item .advisory[data-v-a1db32ee]:hover{border:none;background-color:#F39;color:#FFF
}
.gallery-item:hover img[data-v-a1db32ee]{-webkit-filter:brightness(90%);filter:brightness(90%)
}
.gallery-item:hover h3[data-v-a1db32ee]{color:#FF3399
}

.tag[data-v-cc235648]{margin:0 3px;font-size:12px;color:#4C91E2;cursor:pointer
}
.tag[data-v-cc235648]:hover{color:#FF3399
}

#pagination[data-v-2cb2f759]{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-pack:center;-ms-flex-pack:center;justify-content:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;font-weight:bold;padding:10px 20px
}
#pagination #total[data-v-2cb2f759]{margin-left:10px;font-size:16px;color:#595b56;line-height:24px
}
#pagination #page[data-v-2cb2f759]{width:50px;border:solid 1px #d9d9d9;border-radius:3px;height:40px;text-align:center;font-size:16px
}
#pagi-box[data-v-2cb2f759]{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-align:center;-ms-flex-align:center;align-items:center;border-radius:3px;list-style:none
}
#pagi-box li[data-v-2cb2f759]{padding:10px 17px;border-right:solid 1px #d9d9d9;border-radius:45px;font-size:16px;color:#595b56;line-height:24px;cursor:pointer
}
#pagi-box li[data-v-2cb2f759]:not(.disabled):not(.cur):hover{border:solid 1px #ff3399 !important;color:#ff3399
}
#pagi-box .cur[data-v-2cb2f759]{border:solid 1px #ff3399 !important;background-color:#ff3399;color:#fff
}
#pagi-box .disabled[data-v-2cb2f759]{background-color:#e5e5e5;border:solid 1px #e5e5e5;color:#aaa;cursor:no-drop
}

.shop-detail-box[data-v-3e1ae0e7]{width:980px;margin:0 auto
}
.shop-detail-box main[data-v-3e1ae0e7]{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-pack:justify;-ms-flex-pack:justify;justify-content:space-between;-webkit-box-align:start;-ms-flex-align:start;align-items:flex-start;padding:20px
}
.shop-detail-box #advisory-btn[data-v-3e1ae0e7]{display:inline-block;width:150px;height:35px;line-height:35px;margin-top:20px;text-align:center;border:solid 1px #f39;color:#f39;font-size:16px;font-weight:500
}
.shop-detail-box #advisory-btn[data-v-3e1ae0e7]:hover{background-color:#f39;color:#fff
}
#good-box header[data-v-3e1ae0e7]{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-align:start;-ms-flex-align:start;align-items:flex-start
}
#good-box .cover[data-v-3e1ae0e7]{width:300px;-webkit-box-shadow:0 0 3px 3px #f2f2f2;box-shadow:0 0 3px 3px #f2f2f2
}
#good-box .introduce-box[data-v-3e1ae0e7]{width:300px;margin-left:40px
}
#good-box .introduce-box h4[data-v-3e1ae0e7]{color:#000000
}
#good-box .introduce-box p[data-v-3e1ae0e7]{font-size:14px;line-height:22px
}
#good-box .introduce-box label[data-v-3e1ae0e7]{color:#434040
}
#good-box .introduce-box .price[data-v-3e1ae0e7]{color:#f39;font-size:16px;line-height:24px;font-weight:600
}
#good-box footer[data-v-3e1ae0e7]{margin-top:40px
}
.try-on-box[data-v-3e1ae0e7]{padding:10px
}
.try-on-box img[data-v-3e1ae0e7]{width:550px;margin:0 auto
}

.breadcrumb-box[data-v-1a1c4832]{padding-top:20px;font-size:14px;font-weight:600
}
.breadcrumb-box a[data-v-1a1c4832]{color:#FF3399
}
.breadcrumb-box a[data-v-1a1c4832]:hover{color:#609EE6 !important
}
.breadcrumb-box span[data-v-1a1c4832]{color:#B9B9B9
}

.card-box[data-v-f0cbf588]{width:auto;border:solid 1px #D6D6D6;-webkit-box-shadow:0 0px 10px #D6D6D6;box-shadow:0 0px 10px #D6D6D6;text-align:center
}
.card-box .title[data-v-f0cbf588]{padding:15px;background-color:#000000;color:white;font-size:16px
}
.card-box .tip[data-v-f0cbf588]{padding:20px 0;font-size:14px;color:#aaaaaa
}

.good-box[data-v-09174fbf]{padding:20px;cursor:pointer
}
.good-box img[data-v-09174fbf]{width:200px
}
.good-box span[data-v-09174fbf]{display:inline-block;padding:5px 0;font-weight:500;color:#f39
}
.good-box p[data-v-09174fbf]{width:200px;font-size:14px;line-height:22px
}
.good-box:hover p[data-v-09174fbf]{color:#f39
}
.good-box:hover img[data-v-09174fbf]{-webkit-filter:brightness(90%);filter:brightness(90%)
}

.photo-detail-box[data-v-115957c0]{width:980px;margin:0 auto
}
.photo-detail-box .photo-gallery-title[data-v-115957c0]{margin-top:40px
}
header[data-v-115957c0]{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-pack:start;-ms-flex-pack:start;justify-content:flex-start;-webkit-box-align:start;-ms-flex-align:start;align-items:flex-start;padding:20px 0
}
header .detail-card[data-v-115957c0]{-webkit-box-flex:1;-ms-flex:1;flex:1
}
header .detail[data-v-115957c0]{padding:10px
}
header .detail img[data-v-115957c0]{width:600px;margin:0 auto
}
header .detail p[data-v-115957c0]{width:600px;margin:0 auto;font-size:14px;line-height:28px;text-align:left;text-indent:28px
}
header aside[data-v-115957c0]{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-orient:vertical;-webkit-box-direction:normal;-ms-flex-direction:column;flex-direction:column;width:280px;margin-left:20px
}
header .download-card[data-v-115957c0]{margin:10px 0
}
header .aside-download[data-v-115957c0]{padding:20px
}
header .aside-download p[data-v-115957c0]{width:150px;margin:0 auto;font-size:12px;line-height:24px;font-weight:bold
}
header .aside-download .colorful[data-v-115957c0]{font-size:16px;background-image:linear-gradient(-45deg, #FF2E93, red, #FF2E93, #fff, red, #FF2E93, #fff, #FF2E93);-webkit-text-fill-color:transparent;-webkit-background-clip:text;-webkit-background-size:200% 100%;-webkit-animation:bgp-data-v-115957c0 1s linear infinite;animation:bgp-data-v-115957c0 1s linear infinite
}
header .download-img-box[data-v-115957c0]{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-align:center;-ms-flex-align:center;align-items:center;-webkit-box-pack:center;-ms-flex-pack:center;justify-content:center
}
header .download-img-box .download-img[data-v-115957c0]{width:150px;margin:0 auto;padding:10px 0
}
header .download-img-box .arrow[data-v-115957c0]{position:relative;width:29px
}
header .download-img-box .arrow01[data-v-115957c0]{-webkit-animation:arrow01-data-v-115957c0 400ms linear infinite alternate;animation:arrow01-data-v-115957c0 400ms linear infinite alternate
}
header .download-img-box .arrow02[data-v-115957c0]{-webkit-animation:arrow02-data-v-115957c0 400ms linear infinite alternate;animation:arrow02-data-v-115957c0 400ms linear infinite alternate
}
header .aside-tag[data-v-115957c0]{padding:20px;text-align:left
}
@-webkit-keyframes bgp-data-v-115957c0{
0%{background-position:0 0
}
100%{background-position:-100% 0
}
}
@keyframes bgp-data-v-115957c0{
0%{background-position:0 0
}
100%{background-position:-100% 0
}
}
@-webkit-keyframes arrow01-data-v-115957c0{
0%{right:0
}
100%{right:-15px
}
}
@keyframes arrow01-data-v-115957c0{
0%{right:0
}
100%{right:-15px
}
}
@-webkit-keyframes arrow02-data-v-115957c0{
0%{right:0
}
100%{right:15px
}
}
@keyframes arrow02-data-v-115957c0{
0%{right:0
}
100%{right:15px
}
}

.model-info-box[data-v-4648be15]{width:240px;padding:20px;-webkit-box-shadow:0 0 5px #D6D6D6;box-shadow:0 0 5px #D6D6D6;background-color:white;font-size:14px
}
.model-info-box header[data-v-4648be15]{position:relative;display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-align:center;-ms-flex-align:center;align-items:center
}
.model-info-box header span[data-v-4648be15]{font-size:16px;font-weight:bold;margin-left:20px
}
.model-info-box .head-img[data-v-4648be15]{width:66px;border-radius:33px
}
.model-info-box .focus-img[data-v-4648be15]{position:absolute;top:11.5px;right:-30px;width:83px
}
.model-info-box .info[data-v-4648be15]{padding:20px 0
}
.model-info-box .info .info-item[data-v-4648be15]{padding:3px;display:-webkit-box;display:-ms-flexbox;display:flex
}
.model-info-box .info .info-item .key[data-v-4648be15]{width:45px;color:#9D9D9D;margin-right:5px
}
.model-info-box .info .info-item .value[data-v-4648be15]{-webkit-box-flex:1;-ms-flex:1;flex:1;color:black
}

.photo-gallery-box[data-v-108008b7]{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-pack:justify;-ms-flex-pack:justify;justify-content:space-between;-webkit-box-align:start;-ms-flex-align:start;align-items:flex-start;padding:10px 0 40px 0
}
.photo-gallery-box .photo-gallery-item[data-v-108008b7]:not(:last-child){padding-right:10px
}

.photo-gallery-item-box[data-v-1832ff14]{padding:5px;-webkit-box-shadow:0 0 5px #d6d6d6;box-shadow:0 0 5px #d6d6d6;background-color:white
}
.photo-gallery-item-box img[data-v-1832ff14]{width:137px;cursor:pointer
}
.photo-gallery-item-box h5[data-v-1832ff14]{width:137px;overflow:hidden;white-space:nowrap;text-overflow:ellipsis;font-size:14px;line-height:32px
}
.photo-gallery-item-box[data-v-1832ff14]:hover{-webkit-transform:translateY(-10px);transform:translateY(-10px);-webkit-box-shadow:0 0 5px 5px #d6d6d6;box-shadow:0 0 5px 5px #d6d6d6
}

.text-bar-box[data-v-641cf340]{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-pack:justify;-ms-flex-pack:justify;justify-content:space-between;-webkit-box-align:center;-ms-flex-align:center;align-items:center;font-size:14px;font-weight:bold
}
.text-bar-box a[data-v-641cf340]{color:#4C92E2;cursor:pointer
}

#photo[data-v-2c0bc6a3]{width:980px;margin:0 auto;padding:40px 0
}

.label-box[data-v-05707cda]{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-pack:start;-ms-flex-pack:start;justify-content:flex-start;-webkit-box-align:center;-ms-flex-align:center;align-items:center;width:100%;padding:20px 0
}
.label-box .label-item[data-v-05707cda]{width:80px;height:28px;line-height:30px;text-align:center;margin-right:10px;border:solid 1px #4A4A4A;border-radius:20px
}
.label-box .label-item h3[data-v-05707cda]{color:#4a4a4a;font-size:14px
}
.label-box .label-item[data-v-05707cda]:hover{border:solid 1px #F39
}
.label-box .label-item:hover h3[data-v-05707cda]{color:#F39
}
.label-box .active[data-v-05707cda]{border:none;background-color:#F39
}
.label-box .active h3[data-v-05707cda]{color:#FFF
}

#about-box[data-v-6ac1b28f]{width:980px;margin:0 auto;padding:40px 0
}
#about[data-v-6ac1b28f]{display:-webkit-box;display:-ms-flexbox;display:flex;font-size:14px;padding:20px 0 40px 0
}
#about .side-bar[data-v-6ac1b28f]{width:250px;background-color:white;border-right:solid 1px #f2f2f2
}
#about .side-bar .side-item[data-v-6ac1b28f]{padding:15px 20px;font-weight:500
}
#about .side-bar .side-item a[data-v-6ac1b28f]{color:#4A4A4A
}
#about .side-bar .side-item:hover a[data-v-6ac1b28f]{color:#F39
}
#about .about-content[data-v-6ac1b28f]{-webkit-box-flex:1;-ms-flex:1;flex:1;background-color:white
}
.active[data-v-6ac1b28f]{border-left:solid 2px #f39
}
.active a[data-v-6ac1b28f]{color:#F39 !important
}

.about-view h4[data-v-590b6e5c]{padding:15px 20px
}
.about-view article[data-v-590b6e5c]{padding:15px 20px;text-indent:28px;line-height:22px
}

.report-box[data-v-7ffc60ca]{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-orient:vertical;-webkit-box-direction:normal;-ms-flex-direction:column;flex-direction:column;-webkit-box-align:end;-ms-flex-align:end;align-items:flex-end;padding:0 28px
}
.report-box textarea[data-v-7ffc60ca]{-webkit-box-sizing:border-box;box-sizing:border-box;width:100%;border:1px solid;height:195px;font-size:14px;line-height:28px;padding:5px 10px
}
.report-box button[data-v-7ffc60ca]{width:70px;height:28px;line-height:28px;margin-top:10px;font-weight:600;background:#F64690;color:white
}
